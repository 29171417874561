import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import { css } from "@emotion/react"

import Img from "gatsby-image"
import Layout from "@components/layout"
import { Wrap, ProductsTop, Item } from "@components/products"

import opengraphImage from "../images/opengraph.jpg"

export default function CollectionPage({ data: { allMdx, allFile, mainPlaceholder } }) {
  const { edges: products } = allMdx
  const { nodes: images } = allFile
  return (
    <Layout>
      <Helmet>
        <title>DUNK.SUPPLY</title>
        <meta property="og:title" content="DUNK.SUPPLY" />
        <meta property="og:image" content={opengraphImage} />
        <meta property="og:type" content="website" />
      </Helmet>
      <Wrap>
        <ProductsTop>
          {products.map(({ node: product }) => {
            const image = images.find(image => image.relativePath.includes(product.fields.relativeSlug)).childImageSharp.fluid

            return (
              <Item key={product.id}>
                <div>
                  <figure style={{ position: "relative" }}>
                    <Img
                      fluid={image}
                      durationFadeIn={333}
                      alt=""
                    />
                    <Rank rank={product.frontmatter.rank} />
                  </figure>
                  <h3>
                    {product.frontmatter.collab ? `${product.frontmatter.collab} x ` : null}
                    {product.frontmatter.brand} {product.frontmatter.model}
                  </h3>
                </div>
              </Item>
            )
          })}
        </ProductsTop>
      </Wrap>
    </Layout>
  )
}

function Rank({ rank }) {
  return (
    <figcaption css={css`
      font-family: var(--monospace);
      font-size: ${rank < 3 ? "1rem" : "0.75rem" };
      font-weight: ${rank < 3 ? "600" : "400" };
      letter-spacing: 0.0625em;
      text-transform: uppercase;
      background-color: var(--border-color);
      padding: 0.5rem 1rem;
      position: absolute;
      top: 0;
      left: 0;
    `}>#{rank}</figcaption>
  )
}

export const pageQuery = graphql`
  {
    allMdx(
      sort: {
        order: [ASC],
        fields: [frontmatter___rank]
      }
      filter: {
        fileAbsolutePath: { regex: "\\/top-10-2021/" }
      }
    ) {
      edges {
        node {
          id
          frontmatter {
            brand
            collab
            model
            rank
          }
          fields {
            relativeSlug
            slug
          }
        }
      }
    }
    allFile(filter: { relativePath: { regex: "/main.png$/" } }) {
      nodes {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
        relativePath
      }
    }
  }
`
